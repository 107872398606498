import * as React from "react"
import { useState } from "react"
import { Helmet } from "react-helmet"
import "@fontsource/kanit"
import axios from 'axios';

const SalimOMaticPage = () => {
  const [quote, setQuote] = useState("ไอ้ทอนขายชาติ ประยุทธ์จงเจริญ");
  const [status, setStatus] = useState(0);
  const [result, setResult] = useState(0);

  const handleChange = (event) => {
    setQuote(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setStatus(1);
    axios.post(`https://salim-o-matic.herokuapp.com/detect`, { input: quote })
      .then(res => {
        setStatus(2);
        setResult(res.data.probability);
      });
  }

  const renderStatus = () => {
    if (status == 0) {
      return null
    } else if (status == 1) {
      return (
        <>
          <p className="text-2xl text-gray-100 animate-pulse">
            ANALYZING THIS AMAZING QUOTE, PLEASE WAIT...
          </p>
          <p className="text-xl text-gray-100 animate-pulse">
            กำลังประมวลผลด้วยเทคโนโลยีสุดล้ำ กรุณารอสักครู่...
          </p>
        </>
      );
    } else if (status == 2) {
      return (
        <span className="text-7xl text-blue-200 font-bold">
          {result}% SALIM
        </span>
      );
    }
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'th',
        }}
      >
        <title>Salim-o-matic</title>
      </Helmet>
      <div className="min-h-screen w-full bg-black pt-16 pb-16 md:pt-16 px-4">
        <div className="mx-auto max-w-4xl w-full flex-1 my-4 text-center">
          <p className="text-gray-50 text-8xl font-bold m-6 text-white-shadow">
            🤖
          </p>
          <p className="text-gray-50 md:text-8xl text-5xl font-bold m-6 text-white-shadow">
            <span className="thai-red">Salim</span>
            <span className="thai-white">-o-</span>
            <span className="thai-blue">matic</span>
          </p>
          <p className="text-gray-200 md:text-xl text-md mt-2">Check whether this quote considered as Salim or not</p>
          <p className="text-gray-200 md:text-xl text-md mt-2">เช็คดูว่าประโยคนี้มีความเป็นสลิ่มกี่เปอร์เซนต์</p>
        </div>
        <div className="text-center my-12">
          {renderStatus()}
        </div>
        <div className="mx-auto max-w-4xl w-full my-4 text-center">
          <textarea
            rows="3"
            type="text"
            className="shadow appearance-none border rounded md:w-1/2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleChange}
            value={quote}
            placeholder="Type something here..."
          />
          <br />
          <button className="rounded md:w-1/2 w-full bg-white py-2 px-6 bg-green-500 text-white font-bold text-2xl" onClick={handleSubmit}>Analyze</button>
        </div>
        <div className="mx-auto max-w-4xl w-full mt-12 text-center text-gray-400">
          <p className="text-md font-bold">
            What is salim?
          </p>
          <p className="text-md">
            Salim is a political term used in Thailand, referring to a person who support military government
          </p>
        </div>

        <div className="mx-auto max-w-4xl w-full mt-4 text-center text-gray-500">
          <p className="text-sm">
            Prediction model trained using data from <a href="https://github.com/narze/awesome-salim-quotes">https://github.com/narze/awesome-salim-quotes</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default SalimOMaticPage
